.mt-0 {
  margin-top: 0 !important;
}
.mt-0-5 {
  margin-top: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-5 {
  margin-left: 5rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.mr-4 {
  margin-right: 4rem !important;
}
.mr-5 {
  margin-right: 5rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.mx-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mx-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.pt-0 {
  padding-top: 0rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pt-2 {
  padding-top: 2rem !important;
}
.pt-3 {
  padding-top: 3rem !important;
}
.pt-4 {
  padding-top: 4rem !important;
}
.pt-5 {
  padding-top: 5rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-2 {
  padding-bottom: 2rem !important;
}
.pb-3 {
  padding-bottom: 3rem !important;
}
.pb-4 {
  padding-bottom: 4rem !important;
}
.pb-5 {
  padding-bottom: 5rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pl-3 {
  padding-left: 3rem !important;
}
.pl-4 {
  padding-left: 4rem !important;
}
.pl-5 {
  padding-left: 5rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pr-2 {
  padding-right: 2rem !important;
}
.pr-3 {
  padding-right: 3rem !important;
}
.pr-4 {
  padding-right: 4rem !important;
}
.pr-5 {
  padding-right: 5rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.cursor-pointer {
  cursor: pointer;
}

.w-0 {
  width: 0;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

.h-100vh {
  height: 100vh;
  padding: 0 !important;
}
.h-50vh {
  height: 50vh;
}

.text-center {
  text-align: center;
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-content-center {
  align-content: center;
}
.align-items-center {
  align-items: center;
}

.line-height-60px {
  line-height: 60px;
}

.line-height-0px {
  line-height: 0;
}

.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.bg-color-dark {
  background-color: #1a4fa3 !important;
}
.bg-color-light {
  background-color: #31caff !important;
}
.bg-color-white {
  background-color: #ffffff !important;
}
.f-color-dark {
  color: #1a4fa3 !important;
}
.f-color-light {
  color: #31caff !important;
}
.f-color-white {
  color: #ffffff !important;
}
.bg-white-transperent-7 {
  background: rgba(255, 255, 255, 0.7);
}
.fs-20 {
  font-size: 20px;
}
.fs-25 {
  font-size: 25px;
}
@media (max-width: 767px) {
  .w-50 {
    width: 100%;
  }
}
